<template>
  <div class="card-area-v" id="card-area-v">
    <img
      src="../assets/card_v.png"
      alt=""
      width="357"
      height="625"
      class="d-inline-block pt-n5"
    />
    <div class="empNameV">
      {{ empName }}
    </div>
    <br />
    <div class="empNameEnV">
      {{ empNameEn }}
    </div>
  </div>



  <div class="mt-4">
      Vertical Card: Recommended for snapchat, whatsapp status, instagram stories
      <br />

      <button
      type="button"
      class="btn btn-success btn-lg m-3"
      @click="generateCardV()"
    >
        <font-awesome-icon icon="fa-solid fa-download" />
        تحميل | Download
    </button>


<!--      <button
              type="button"
              class="btn btn-success btn-lg m-3"
              @click="CopyCardH()"
      >
          <font-awesome-icon icon="fa-solid fa-copy" />
          نسخ | copy
      </button>-->


  </div>

  <br />
  <hr />
  <br />




    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Horizontal Card</h1>


                    <button
                            type="button"
                            class="btn btn-success btn-lg m-3"
                            @click="generateCardH()"
                    >
                        <font-awesome-icon icon="fa-solid fa-download" />
                        تحميل | Download
                    </button>


                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" id="modal-body">
                    <div class="container-fluid">
                        <div class="row">


                    <div class="card-area-h" id="card-area-h">
                        <img
                                src="../assets/card_h.png"
                                alt=""
                                width="810"
                                height="420"

                                class="d-inline-block pt-n5"
                        />
                        <div class="empNameH">
                            {{ empName }}
                        </div>
                        <br />
                        <div class="empNameEnH">
                            {{ empNameEn }}
                        </div>
                    </div>



                </div>
<!--                <div class="modal-footer">



                </div>-->
            </div>


                </div>
            </div>
        </div>
    </div>






  <div class="mt-4">



      <div class="card-area-H-preview" id="card-area-H-preview">
          <img
                  src="../assets/card_h.png"
                  alt=""
                  width="405"
                  height="210"

                  class="d-inline-block pt-n5 img-responsive"
          />
      </div>



      Horizontal Card: Recommended for linkedin, Twitter, facebook
      <br />





      <button type="button" class="btn btn-success btn-lg m-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
          <font-awesome-icon icon="fa-solid fa-eye" />
          Preview | معاينة

      </button>


<!--    <button
      type="button"
      class="btn btn-success btn-lg m-3"
      @click="generateCardV()"
    >
        <font-awesome-icon icon="fa-solid fa-copy" />
        نسخ | Copy
    </button>-->




  </div>
</template>
<script>
import html2canvas from 'html2canvas';

export default {
  name: 'GentreatedCard',
  props: {
    empName: String,
    empNameEn: String,
  },
  methods: {
    async generateCardV() {
      const canvas = await html2canvas(document.querySelector('#card-area-v'));
      canvas.style.display = 'none';
      document.body.appendChild(canvas);
      const image = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const a = document.createElement('a');

      a.setAttribute('download', `My-Salam-Vcard.png`);
      a.setAttribute('href', image);
      a.click();
    },

    async generateCardH() {
      const canvas = await html2canvas(document.getElementById('modal-body'));
      canvas.style.display = 'none';
      document.body.appendChild(canvas);
      const image = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const a = document.createElement('a');

      a.setAttribute('download', `My-Salam-Hcard.png`);
      a.setAttribute('href', image);
      a.click();
    },

/*      async CopyCardH(){
          const canvas = await html2canvas(document.querySelector('#card-area-v'));
          canvas.focus();
          document.execCommand('copy');

      },
      async CopyCardV(){
          const canvas = await html2canvas(document.querySelector('#card-area-v'));
          canvas.focus();
          document.execCommand('copy');
      }*/


  },
};
</script>
<style>
.card-area-v {
  position: relative;
  text-align: center;
  width: 357px;
  height: 625px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #00b140;
}

.card-area-h {
/*    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #00b140;*/
    transform: rotate(90deg);


}

    .empNameV {
  font-family: 'Tajawal', sans-serif !important;
  font-size: 18px;

  position: absolute;
  top: 57%;
  /*left: 35%;*/
  width: 100%;

  color: #002035 !important;
  /* transform: translate(-50%, -50%); */
}
.empNameH {
  font-family: 'Tajawal', sans-serif !important;
  font-size: 20px;

  position: absolute;
  top: 45%;
  left: -3%;
  width: 100%;
    /*transform: rotate(90deg);*/
  color: #002035 !important;
  /* transform: translate(-50%, -50%); */
}

.empNameEnV {
  font-family: 'Tajawal', sans-serif !important;
  font-size: 18px;

  position: absolute;
  top: 84%;
  /*right: 4%;*/
  width: 100%;

  color: #002035 !important;
  /* transform: translate(-50%, -50%); */
}
.empNameEnH {
  font-family: 'Tajawal', sans-serif !important;
  font-size: 20px;

  position: absolute;
  top: 78%;
  left: -1%;
  width: 100%;
    /*transform: rotate(90deg);*/
  color: #002035 !important;
  /* transform: translate(-50%, -50%); */
}


.modal-body {
    position: relative;
    overflow-y: auto;
    height:600px !important;
}
</style>
